import * as actions from './actions'
import Api from 'utils/api'

export const enableFeature: Function = (uuid): string => async (
  dispatch: Function,
): Promise<any> => {
  dispatch(actions.enableFeatureRequest())
  try {
    await Api.request(`/features/${uuid}/status?enabled=true`, {
      method: 'PUT',
    })
    dispatch(actions.enableFeatureSuccess('Функция была успешно активирована'))
    return Promise.resolve()
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.enableFeatureFailure(message))
    return Promise.reject(message)
  }
}

export const disableFeature: Function = (uuid): string => async (
  dispatch: Function,
): Promise<any> => {
  dispatch(actions.disableFeatureRequest())
  try {
    await Api.request(`/features/${uuid}/status?enabled=false`, {
      method: 'PUT',
    })
    dispatch(
      actions.disableFeatureSuccess('Функция была успешно деактивирована'),
    )
    return Promise.resolve()
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.disableFeatureFailure(message))
    return Promise.reject(message)
  }
}
