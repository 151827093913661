// https://github.com/diegohaz/arc/wiki/Atomic-Design#templates
import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Container } from 'sputnik-ui'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  height: calc(100% - 4.125rem);
  margin-top: 4.125rem;
`

const Header = styled.header`
  position: static;
  top: 0;
  width: 100%;
  z-index: 999;
`

const Content = styled.section``

const MainTemplate = ({ header, children, margin, subheader, ...props }) => {
  return (
    <Wrapper {...props}>
      {header}
      <Content margin={margin}>{children}</Content>
    </Wrapper>
  )
}

MainTemplate.propTypes = {
  header: PropTypes.node.isRequired,
  subheader: PropTypes.node,
  children: PropTypes.any.isRequired,
  margin: PropTypes.string,
}

export default MainTemplate
