export default {
  baseUri:
    process.env.APP_BASE_URL ||
    'https://sso.sputnik.systems',
  redirectUri:
    process.env.APP_REDIRECT_URL ||
    'https://sso.sputnik.systems',
  ssoUri: process.env.APP_SSO_URL || 'https://sso.sputnik.systems',
  // baseUri: 'https://sso-test.k8s.sputnik.systems',
  // redirectUri: 'https://sso-test.k8s.sputnik.systems',
  // ssoUri: 'https://sso-test.k8s.sputnik.systems',
  app: 'Управление Аккаунтами',
  brand: 'Спутник',
}
// https://sso-test.k8s.sputnik.systems
