import React from 'react'
import { Select, Button, Wrapper, Container } from 'sputnik-ui'

import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Avatar from '@material-ui/core/Avatar'

const UserCard = ({
  history,
  classes,
  currentUser,
  permissions,
  appAbilities,
  handleSelect,
  updateUserPermissions,
  actions,
}) => (
  <Wrapper
    style={{ flexDirection: 'column', padding: 0 }}
    className={classes.card}
    shadow={false}
    border={false}
  >
    <Container>
      <CardHeader
        // subheaderTypographyProps={{
        //   variant: 'body1',
        // }}
        // avatar={
        //   <Avatar aria-label="Recipe" className={classes.avatar}>
        //     {currentUser.full_name.slice(0, 1).toUpperCase()}
        //   </Avatar>
        // }
        title={currentUser.full_name}
        subheader={currentUser.email}
        action={actions}
        classes={{ action: classes.action }}
        style={{ paddingLeft: 0, paddingRight: 0 }}
      />
    </Container>

    <Divider style={{ marginBottom: '1.625rem' }} />

    <Container>
      <CardContent style={{ paddingLeft: 0, paddingRight: 0 }}>
        {appAbilities.map(x => (
          <div className={classes.column} key={x.app.name}>
            <Typography
              gutterBottom
              variant="h4"
              style={{ width: '100%', fontSize: '1.325rem' }}
            >
              {x.app.name}
            </Typography>
            {Object.entries(x.abilities).map(([key, ability]) => {
              const options = ability.privileges.map(s => ({
                value: s.action,
                label: s.translation,
                isDisabled: s.is_feature,
              }))
              const selected =
                (permissions[x.app.app_id] &&
                  permissions[x.app.app_id][
                    `${ability.namespace ? ability.namespace + '/' : ''}${
                      ability.name
                    }`
                  ]) ||
                null

              const selectedOptions = options.filter(
                o => selected && selected.includes(o.value),
              )
              return (
                <div className={classes.row} key={key}>
                  <Typography className={classes.subcolumn}>
                    {ability.translation}
                  </Typography>
                  <div className={classes.permissionColumn}>
                    <Select
                      handleSelect={handleSelect(x.app.app_id, ability)}
                      selected={selectedOptions}
                      options={options}
                      placeholder="Список действий, доступных пользователю"
                    />
                  </div>
                </div>
              )
            })}
          </div>
        ))}
      </CardContent>
      <div
        style={{
          display: 'flex',
          paddingRight: 0,
          justifyContent: 'center',
          position: 'fixed',
          bottom: '1rem',
          width: '100%',
        }}
      >
        <Button
          variant="contained"
          rounded
          color="primary"
          size="fullScreen"
          onClick={updateUserPermissions}
        >
          обновить данные
        </Button>
      </div>
    </Container>
  </Wrapper>
)

export default UserCard
