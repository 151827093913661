import * as types from './types'

const initialState = {
  msg: '',
  error: '',
  loading: false,
}

export default function features(state = initialState, { type, payload }) {
  switch (type) {
    case types.ENABLE_FEATURE_REQUEST:
      return { ...state, loading: true }
    case types.ENABLE_FEATURE_SUCCESS:
      return { ...state, msg: payload.msg, loading: false }
    case types.ENABLE_FEATURE_FAILURE:
      return { ...state, error: payload.error, loading: false }
    case types.DISABLE_FEATURE_REQUEST:
      return { ...state, loading: true }
    case types.DISABLE_FEATURE_SUCCESS:
      return { ...state, msg: payload.msg, loading: false }
    case types.DISABLE_FEATURE_FAILURE:
      return { ...state, error: payload.error, loading: false }

    case 'ui/CLEAR_STATUS':
      return { ...state, error: '', msg: '' }
    default:
      return state
  }
}
