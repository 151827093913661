import React from 'react'
import { Button } from 'sputnik-ui'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

export default ({ classes, open, handleClose, onConfirm }) => (
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      Вы точно хотите удалить данного пользователя?
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Это действие нельзя будет отменить.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary" autoFocus rounded>
        Отмена
      </Button>
      <Button onClick={onConfirm} rounded color="danger">
        Подтвердить
      </Button>
    </DialogActions>
  </Dialog>
)
