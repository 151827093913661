//@flow
export const GET_USERS_REQUEST: string = 'users/GET_USERS_REQUEST'
export const GET_USERS_SUCCESS: string = 'users/GET_USERS_SUCCESS'
export const GET_USERS_ERROR: string = 'users/GET_USERS_ERROR'

export const GET_CURRENT_USER_REQUEST: string = 'users/GET_CURRENT_USER_REQUEST'
export const GET_CURRENT_USER_SUCCESS: string = 'users/GET_CURRENT_USER_SUCCESS'
export const GET_CURRENT_USER_ERROR: string = 'users/GET_CURRENT_USER_ERROR'

export const INVITE_USER_REQUEST: string = 'users/INVITE_USER_REQUEST'
export const INVITE_USER_SUCCESS: string = 'users/INVITE_USER_SUCCESS'
export const INVITE_USER_ERROR: string = 'users/INVITE_USER_ERROR'

export const GET_USER_PERMISSIONS_REQUEST: string =
  'users/GET_USER_PERMISSIONS_REQUEST'
export const GET_USER_PERMISSIONS_SUCCESS: string =
  'users/GET_USER_PERMISSIONS_SUCCESS'
export const GET_USER_PERMISSIONS_ERROR: string =
  'users/GET_USER_PERMISSIONS_ERROR'

export const UPDATE_USER_PERMISSIONS_REQUEST: string =
  'users/UPDATE_USER_PERMISSIONS_REQUEST'
export const UPDATE_USER_PERMISSIONS_SUCCESS: string =
  'users/UPDATE_USER_PERMISSIONS_SUCCESS'
export const UPDATE_USER_PERMISSIONS_ERROR: string =
  'users/UPDATE_USER_PERMISSIONS_ERROR'

export const BLOCK_USER_REQUEST: string = 'users/BLOCK_USER_REQUEST'
export const BLOCK_USER_SUCCESS: string = 'users/BLOCK_USER_SUCCESS'
export const BLOCK_USER_ERROR: string = 'users/BLOCK_USER_ERROR'

export const DELETE_USER_REQUEST: string = 'users/DELETE_USER_REQUEST'
export const DELETE_USER_SUCCESS: string = 'users/DELETE_USER_SUCCESS'
export const DELETE_USER_ERROR: string = 'users/DELETE_USER_ERROR'

export const UPDATE_USER_REQUEST: string = 'users/UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS: string = 'users/UPDATE_USER_SUCCESS'
export const UPDATE_USER_ERROR: string = 'users/UPDATE_USER_ERROR'

export const UPDATE_CURRENT_USER_REQUEST: string =
  'users/UPDATE_CURRENT_USER_REQUEST'
export const UPDATE_CURRENT_USER_SUCCESS: string =
  'users/UPDATE_CURRENT_USER_SUCCESS'
export const UPDATE_CURRENT_USER_ERROR: string =
  'users/UPDATE_CURRENT_USER_ERROR'

export const CONFIRM_PHONE_NUMBER_REQUEST: string =
  'users/CONFIRM_PHONE_NUMBER_REQUEST'
export const CONFIRM_PHONE_NUMBER_SUCCESS: string =
  'users/CONFIRM_PHONE_NUMBER_SUCCESS'
export const CONFIRM_PHONE_NUMBER_ERROR: string =
  'users/CONFIRM_PHONE_NUMBER_ERROR'

export const CLEAR_USERS: string = 'users/CLEAR_USERS'

export type requestAction = {
  type: string,
}

export type successAction = {
  type: string,
  payload?: any,
}

export type errorAction = {
  type: string,
  payload: string,
}

export type User = {
  id: number,
  email: string,
}
