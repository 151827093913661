import React, { useState, useCallback, useEffect } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import {
  Loader,
  Button,
  Toast,
  Avatar,
  useInput,
  Container,
  FullscreenExitButton,
  FullscreenDialogTitle,
} from 'sputnik-ui'
import { withRouter, useHistory, useRouteMatch } from 'react-router-dom'
import isEmail from 'validator/lib/isEmail'

import { updateCurrentUser } from 'store/users/operations'
import { checkAuth } from 'store/auth/operations'
import { clearStatus } from 'store/ui/actions'
import { ReactComponent as AccountIcon } from './account.svg'
import {
  Dialog,
  DialogActions,
  TextField,
  Toolbar,
  Fade,
  Typography,
  ExpansionPanel,
  FormControlLabel,
  Switch,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  IconButton,
} from '@material-ui/core'
import { ExpandMore, ChevronLeft } from '@material-ui/icons'
import { AsYouType } from 'libphonenumber-js'
import ConfirmPhoneModal from 'components/molecules/ConfirmPhoneModal'
import { disableFeature, enableFeature } from 'store/features/operations'

const Transition = props => <Fade in {...props} />

const MARGIN = 2

const Body = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  /* margin-top: 4rem; */
  /* margin-bottom: 4rem; */
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: ${p => p.theme.sizes.tablet};
  width: 100%;
`

const Row = styled.div`
  display: flex;
  align-items: center;
`

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1.625rem;
  width: 100%;

  button:first-child {
    margin-right: 0.625rem;
  }
`

const styles = {
  video: {
    backgroundColor: '#fafafa',
    borderRadius: 30,
    width: '100%',
    marginBottom: '1rem',
    height: 0,
    paddingBottom: '56.25%',
  },
}

function PhoneInputCustom(props) {
  return <TextField {...props}></TextField>
}

function MyProfilePage({ ...props }) {
  const history = useHistory()
  const match = useRouteMatch()
  const dispatch = useDispatch()
  const user = useSelector(({ auth }) => auth.user)
  const { msg, error, featureToggling } = useSelector(
    ({ users, features }) => ({
      msg: users.msg || features.msg,
      error: users.error || features.error,
      featureToggling: features.loading,
    }),
  )

  const [modifiedInfo, setModifiedInfo] = useState(false)
  const setModifiedInfoToTrue = () => setModifiedInfo(true)

  const { bind: bindEmail, value: email } = useInput(
    (user && user.email) || '',
    setModifiedInfoToTrue,
  )
  const { bind: bindName, value: name, setValue: setName } = useInput(
    (user && user.name) || '',
    setModifiedInfoToTrue,
  )
  const { bind: bindSurname, value: surname, setValue: setSurname } = useInput(
    (user && user.surname) || '',
    setModifiedInfoToTrue,
  )
  const { value: phone, setValue: setPhone } = useInput(
    (user && user.phone) || '',
    setModifiedInfoToTrue,
  )

  // useEffect(() => {
  //   setName(user.name)
  //   setSurname(user.surname)
  //   setPhone(user.phone)
  // }, [user])

  const handleReturn = useCallback(() => {
    const regExp = /.*account.sputnik.systems\/user\/me\?redirect_to=(.*)/
    const m = regExp.exec(window.location)
    const redirectUrl = m && m[1]

    redirectUrl ? (window.location = redirectUrl) : history.push('/')
  }, [window.location])

  const [confirmPhoneModal, setConfirmPhoneModal] = useState(false)
  const handleSubmit = e => {
    e.preventDefault()

    dispatch(updateCurrentUser({ name, surname, phone })).then(
      ({ confirmation_required }) => {
        if (confirmation_required) {
          setConfirmPhoneModal(true)
        } else {
          handleReturn()
        }
      },
    )
    // .then(handleReturn)
  }

  const formatPhone = value => {
    return new AsYouType('RU').input(value)
  }

  const features = Object.values(user?.features || {}).flat(1)

  return (
    <Dialog fullScreen open={true} TransitionComponent={Transition} {...props}>
      {/* <Toolbar style={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant="h6" color="inherit">
          Мой профиль
        </Typography>
      </Toolbar> */}
      <FullscreenExitButton onClick={handleReturn}></FullscreenExitButton>
      <FullscreenDialogTitle>Мой профиль</FullscreenDialogTitle>
      <Body>
        {/* <Row style={{ marginBottom: '2rem' }}>
          <IconButton onClick={handleReturn}>
            <ChevronLeft />
          </IconButton> 

          <Typography variant="h4">Мой профиль</Typography>
        </Row> */}

        {/* <AccountIcon style={{ maxHeight: 128 }}></AccountIcon> */}
        {/* <Typography variant="h5" gutterBottom>
          Настройки профиля
        </Typography> */}

        <Form onSubmit={handleSubmit}>
          <TextField margin="dense" label="E-mail" disabled {...bindEmail} />

          <TextField
            name="tel"
            autoComplete="tel"
            label="Номер телефона"
            value={formatPhone(phone)}
            onChange={e => {
              setPhone(e.target.value)
              setModifiedInfoToTrue()
            }}
          ></TextField>

          <Row>
            <TextField
              name="name"
              margin="dense"
              label="Имя"
              style={{ marginRight: 20 }}
              fullWidth
              autoComplete="name"
              {...bindName}
            />
            <TextField
              name="surname"
              margin="dense"
              label="Фамилия"
              fullWidth
              autoComplete="surname"
              {...bindSurname}
            />
          </Row>
          <TextField
            margin="dense"
            disabled
            label="Лицевой счет"
            value={user && user.company.identifier}
          />
          <Button
            type="submit"
            primary
            rounded
            size="small"
            variant="contained"
            disabled={!modifiedInfo}
            style={{ marginLeft: 'auto', marginTop: '.325rem' }}
          >
            сохранить изменения
          </Button>

          <Typography
            gutterBottom
            align="center"
            variant="h5"
            style={{ marginTop: '2rem' }}
          >
            Экспериментальные функции
          </Typography>

          <Typography align="center" color="textSecondary" gutterBottom>
            Может быть они даже работают 😌
          </Typography>

          {features.map(f => (
            <ExpansionPanel key={f.uuid}>
              <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                <FormControlLabel
                  aria-label={f.display_name}
                  onClick={event => {
                    event.stopPropagation()

                    f.is_enabled
                      ? dispatch(disableFeature(f.uuid)).then(() =>
                          dispatch(checkAuth()),
                        )
                      : dispatch(enableFeature(f.uuid)).then(() =>
                          dispatch(checkAuth()),
                        )
                  }}
                  onFocus={event => event.stopPropagation()}
                  control={<Switch checked={f.is_enabled} />}
                  label={f.display_name}
                  // disabled={featureToggling}
                  style={{
                    pointerEvents: featureToggling ? 'none' : undefined,
                  }}
                />
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ flexDirection: 'column' }}>
                {/* {video_url && (
                  <iframe
                    // key={url}
                    // width="560"
                    // height="315"
                    // src={url}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen="allowFullScreen"
                    // title={url}
                    style={styles.video}
                  />
                )} */}
                <Typography color="textSecondary">{f.description}</Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}

          <div style={{ height: '2rem' }}></div>
        </Form>
      </Body>
      <ConfirmPhoneModal
        open={confirmPhoneModal}
        phone={phone}
        handleClose={() => setConfirmPhoneModal(false)}
        onSuccess={() => {
          dispatch(checkAuth())
          handleReturn()
        }}
      ></ConfirmPhoneModal>
      <Toast
        open={!!msg}
        variant="info"
        handleClose={() => dispatch(clearStatus())}
      >
        {msg}
      </Toast>
      <Toast
        open={!!error}
        variant="error"
        handleClose={() => dispatch(clearStatus())}
      >
        {error}
      </Toast>
    </Dialog>
  )
}

export default MyProfilePage
