import React from 'react'
import { connect } from 'react-redux'
import { Loader, Button } from 'sputnik-ui'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'

import { getCompanies, updateUser } from 'store/operations'
import {
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'

const Transition = (props) => <Slide direction="up" {...props} />

class AddUserModal extends React.Component {
  state = {
    company: this.props.currentUser.company,
    newSelectedCompany: null,
    page: 0,
    limit: 10,
    q: '',
  }

  componentDidMount() {
    this.props.dispatch(
      getCompanies({
        q: this.state.q,
        page: this.state.page,
        limit: this.state.limit,
      })
    )
  }

  searchCompanies = () => {
    this.props.dispatch(
      getCompanies({
        q: this.state.q,
        page: this.state.page,
        limit: this.state.limit,
      })
    )
  }

  updateUser = () => {
    this.props
      .dispatch(
        updateUser({
          userId: this.props.match.params.id,
          company_id: this.state.company.id,
        })
      )
      .then(this.props.handleClose)
  }

  handleCompanyChange = (e) => {
    const company = e.target.value
    this.setState({ company })
  }

  handleChangePage = (e, page) => {
    this.setState({ page }, this.searchCompanies)
  }

  handleChangeRowsPerPage = (e) => {
    const limit = e.target.value
    this.setState({ limit }, this.searchCompanies)
  }

  handleSearch = (e) => {
    const q = e.target.value
    this.setState({ q }, this.searchCompanies)
  }

  render() {
    const { limit, page } = this.state
    const { open, handleClose, companies, companiesError, meta } = this.props

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        TransitionComponent={Transition}
        fullScreen
      >
        <DialogTitle id="alert-dialog-title">Изменить компанию</DialogTitle>
        <DialogActions>
          <Typography style={{ marginRight: 'auto' }}>
            Компания {this.state.company?.name}
          </Typography>
          <Button onClick={handleClose} secondary rounded autoFocus>
            Отмена
          </Button>
          <Button
            onClick={this.updateUser}
            rounded
            color="primary"
            variant="raised"
          >
            Обновить
          </Button>
        </DialogActions>
        <DialogContent style={{ overflow: 'visible' }}>
          {!companies ? (
            <Loader msg="Загружаем список компаний..." error={companiesError} />
          ) : (
            <>
              <TextField
                style={{ width: 320 }}
                placeholder="Поиск"
                onChange={this.handleSearch}
              />
              <Table
                style={{
                  width: '100%',
                  tableLayout: 'fixed',
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Имя компании</TableCell>
                    <TableCell>Идентификатор</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {companies.map((el) => (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={el.id}
                      onClick={() => this.setState({ company: el })}
                      style={{
                        cursor: 'pointer',
                      }}
                      // data-aos="fade-left"
                    >
                      <TableCell component="th" scope="row" padding="dense">
                        {el.name}
                      </TableCell>
                      <TableCell component="th" scope="row" padding="dense">
                        {el.identifier}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                style={{
                  position: 'fixed',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  background: 'white',
                  boxShadow: '0 0 20px rgba(0,0,0,0.1)',
                }}
                component="div"
                count={meta.pages_count}
                rowsPerPage={limit}
                rowsPerPageOptions={[10, 20, 30]}
                page={page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                labelRowsPerPage="Строк на странице:"
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} из ${count}`
                }
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </>
          )}
        </DialogContent>
      </Dialog>
    )
  }
}

const mapStateToProps = ({ users: { currentUser }, companies }) => ({
  currentUser,
  companies: companies.collection?.companies,
  meta: companies.collection?.meta,
  companiesError: companies.error,
})

export default connect(mapStateToProps)(withRouter(AddUserModal))
