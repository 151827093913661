import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { withRouter } from 'react-router-dom'

const Div = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export default withRouter(({ history }) => (
  <Div>
    <Typography variant="headline" style={{ marginBottom: '2.625rem' }}>
      404
    </Typography>

    <Typography variant="subheading">Данной страницы не существует.</Typography>

    <Button
      color="secondary"
      variant="extendedFab"
      style={{ marginTop: '2.625rem', boxShadow: 'none' }}
      onClick={() => history.push('/')}
    >
      Перейти на главную
    </Button>
  </Div>
))
