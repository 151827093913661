//@flow
import * as types from './types'

export const getUsersRequest = (): types.requestAction => ({
  type: types.GET_USERS_REQUEST,
})

export const getUsersSuccess = (users: Array<Object>): types.successAction => ({
  type: types.GET_USERS_SUCCESS,
  payload: users,
})

export const getUsersError = (err: string): types.errorAction => ({
  type: types.GET_USERS_ERROR,
  payload: err,
})

export const getCurrentUserRequest = (): types.requestAction => ({
  type: types.GET_CURRENT_USER_REQUEST,
})

export const getCurrentUserSuccess = (
  user: types.User,
): types.successAction => ({
  type: types.GET_CURRENT_USER_SUCCESS,
  payload: user,
})

export const getCurrentUserError = (err: string): types.errorAction => ({
  type: types.GET_CURRENT_USER_ERROR,
  payload: err,
})

export const inviteUserRequest = (): types.requestAction => ({
  type: types.INVITE_USER_REQUEST,
})

export const inviteUserSuccess = (): types.successAction => ({
  type: types.INVITE_USER_SUCCESS,
})

export const inviteUserError = (err: string): types.errorAction => ({
  type: types.INVITE_USER_ERROR,
  payload: err,
})

export const getUserPermissionsRequest = (): types.requestAction => ({
  type: types.GET_USER_PERMISSIONS_REQUEST,
})

export const getUserPermissionsSuccess = (
  permissions: Array<Object>,
): types.successAction => ({
  type: types.GET_USER_PERMISSIONS_SUCCESS,
  payload: permissions,
})

export const getUserPermissionsError = (err: string): types.errorAction => ({
  type: types.GET_USER_PERMISSIONS_ERROR,
  payload: err,
})

export const updateUserPermissionsRequest = (): types.requestAction => ({
  type: types.UPDATE_USER_PERMISSIONS_REQUEST,
})

export const updateUserPermissionsSuccess = (
  permissions: Array<Object>,
): types.successAction => ({
  type: types.UPDATE_USER_PERMISSIONS_SUCCESS,
  payload: permissions,
})

export const updateUserPermissionsError = (err: string): types.errorAction => ({
  type: types.UPDATE_USER_PERMISSIONS_ERROR,
  payload: err,
})

export const blockUserRequest = (): types.requestAction => ({
  type: types.BLOCK_USER_REQUEST,
})

export const blockUserSuccess = (response: any): types.successAction => ({
  type: types.BLOCK_USER_SUCCESS,
  payload: response,
})

export const blockUserError = (err: string): types.errorAction => ({
  type: types.BLOCK_USER_ERROR,
  payload: err,
})

export const deleteUserRequest = (): types.requestAction => ({
  type: types.DELETE_USER_REQUEST,
})

export const deleteUserSuccess = (response: any): types.successAction => ({
  type: types.DELETE_USER_SUCCESS,
  payload: response,
})

export const deleteUserError = (err: string): types.errorAction => ({
  type: types.DELETE_USER_ERROR,
  payload: err,
})

export const updateUserRequest = (): types.requestAction => ({
  type: types.UPDATE_USER_REQUEST,
})

export const updateUserSuccess = (response: any): types.successAction => ({
  type: types.UPDATE_USER_SUCCESS,
  payload: response,
})

export const updateUserError = (err: string): types.errorAction => ({
  type: types.UPDATE_USER_ERROR,
  payload: err,
})

export const updateCurrentUserRequest = (): types.requestAction => ({
  type: types.UPDATE_USER_REQUEST,
})
export const updateCurrentUserSuccess = (msg: string): types.successAction => ({
  type: types.UPDATE_USER_SUCCESS,
  payload: { msg },
})
export const updateCurrentUserError = (err: string): types.errorAction => ({
  type: types.UPDATE_USER_ERROR,
  payload: err,
})

export const confirmPhoneNumberRequest = (): types.requestAction => ({
  type: types.UPDATE_USER_REQUEST,
})
export const confirmPhoneNumberSuccess = (
  msg: string,
): types.successAction => ({
  type: types.UPDATE_USER_SUCCESS,
  payload: { msg },
})
export const confirmPhoneNumberError = (err: string): types.errorAction => ({
  type: types.UPDATE_USER_ERROR,
  payload: err,
})

export const clearUsers = () => ({
  type: types.CLEAR_USERS,
})
