//@flow
import * as React from 'react'
import { MainTemplate, AddUserModal, UsersTable } from 'components'
import { Navbar, Loader } from 'sputnik-ui'
import { connect } from 'react-redux'
import type { State, Props } from './types'
import throttle from 'lodash/throttle'

import { withStyles } from '@material-ui/core/styles'

import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import PersonAdd from '@material-ui/icons/PersonAdd'
import SearchIcon from '@material-ui/icons/Search'

import { getUsers } from 'store/operations'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: 24,
  },

  search: {
    minWidth: '300px',
    zIndex: 1200,

    paddingLeft: 10,
  },
})

class HomePage extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      modalIsOpen: false,
      isSearching: false,
      searchQuery: '',
      page: 0,
      limit: 10,
    }

    this.searchUsers = throttle(this.searchUsers, 400)
  }

  componentDidMount = () => {
    this.props.dispatch(
      getUsers({ page: this.state.page, limit: this.state.limit }),
    )
  }

  toggleModal = () =>
    this.setState({
      modalIsOpen: !this.state.modalIsOpen,
    })

  toggleSearch = () => this.setState({ isSearching: !this.state.isSearching })

  searchUsers = () =>
    this.props.dispatch(getUsers({ q: this.state.searchQuery }))

  onChange = ({ currentTarget: { value } }) => {
    this.setState({ searchQuery: value }, this.searchUsers)
  }

  handleChangePage = (event: ?SyntheticEvent<>, page: number) => {
    this.setState({ page }, () => {
      // this.props.dispatch(clearUsers())
      this.props.dispatch(
        getUsers({ page: this.state.page, limit: this.state.limit }),
      )
    })
  }

  handleChangeRowsPerPage = (e: SyntheticEvent<>) => {
    //$FlowFixMe
    this.setState({ limit: e.target.value }, () =>
      this.props.dispatch(
        getUsers({ page: this.state.page, limit: this.state.limit }),
      ),
    )
  }

  render() {
    const { classes, users } = this.props
    const { modalIsOpen, isSearching, page, limit } = this.state

    if (!users) return <Loader msg="Загружаем список пользователей" fullPage />

    return (
      <MainTemplate
        header={
          <Navbar
            logo
            app
            fixed
            user
            actions={
              <React.Fragment>
                {isSearching ? (
                  <TextField
                    autoFocus
                    id="search"
                    type="search"
                    placeholder="имя, компания или e-mail"
                    onBlur={this.toggleSearch}
                    onChange={this.onChange}
                    className={classes.search}
                    value={this.state.searchQuery}
                  />
                ) : (
                  <Tooltip title="Поиск пользователя" placement="bottom">
                    <IconButton
                      color="inherit"
                      onClick={this.toggleSearch}
                      disableRipple
                    >
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                )}

                <Tooltip title="Пригласить пользователя" placement="bottom">
                  <IconButton
                    color="inherit"
                    onClick={this.toggleModal}
                    disableRipple
                  >
                    <PersonAdd />
                  </IconButton>
                </Tooltip>
              </React.Fragment>
            }
          />
        }
      >
        {/* <TableToolbar numSelected={selected.length} /> */}

        <UsersTable
          users={users.users}
          count={users.meta.total}
          page={page}
          handleChangePage={this.handleChangePage}
          limit={limit}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
        />

        <AddUserModal open={modalIsOpen} handleClose={this.toggleModal} />
      </MainTemplate>
    )
  }
}

const mapStateToProps = ({ users }) => ({
  users: users.users,
})

//$FlowFixMe
export default connect(mapStateToProps)(withStyles(styles)(HomePage))
