import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Select, Loader, Button, Toast } from 'sputnik-ui'
import { withRouter } from 'react-router-dom'
import isEmail from 'validator/lib/isEmail'
import { isMobilePhone } from 'validator'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import EmailIcon from '@material-ui/icons/Email'
import InputAdornment from '@material-ui/core/InputAdornment'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Slide from '@material-ui/core/Slide'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import Send from '@material-ui/icons/Send'
import { withStyles } from '@material-ui/core/styles'

import { getApps, inviteUser, getUsers } from 'store/operations'

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 100%;
  padding: 2.625rem;

  @media screen and (min-width: 768px) {
    max-width: 35rem;
  }
`

// codereview-disable-next-line
const Margin = styled.div`
  margin-top: 1.325rem;
  width: 100%;
`

const Transition = (props) => <Slide direction="up" {...props} />

const styles = (theme) => ({
  header: {
    position: 'relative',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
})

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1.625rem;
  width: 100%;

  button:first-child {
    margin-right: 0.625rem;
  }
`

class InviteUserModal extends React.Component {
  state = {
    app_ids: [],
    login: '',
    loginError: false,
    snackbarErr: false,
  }

  componentDidMount() {
    this.props.dispatch(getApps())
  }

  handleSelect = (options) =>
    this.setState({ app_ids: options.map((o) => o.value) })

  handleChange = (prop) => (e) =>
    this.setState({ [prop]: e.target.value, [`${prop}Error`]: false })

  handleinviteUser = () => {
    const { login, app_ids } = this.state
    const body = {
      login,
      app_ids,
    }

    const errors = Object.entries(this.state).reduce((acc, [key, value]) => {
      if (key.search('Error') !== -1) {
        if (value === true) return true
      }
      return acc
    }, false)

    if (!errors) {
      this.props
        .dispatch(inviteUser(body))
        .then(({ user_id }) => {
          this.props.dispatch(getUsers({}))
          this.props.history.push(`/user/${user_id}`)
        })
        .catch((err) => {
          console.log('err', err)
          this.setState({ snackbarErr: true })
        })
    } else {
      this.setState({ snackbarErr: true })
      console.log('errors were found')
    }
  }

  toggleModal = (modal) => () => this.setState({ [modal]: !this.state[modal] })

  handleBlur = (prop) => (e) => {
    if (
      prop === 'login' &&
      !(
        isEmail(this.state.login) ||
        isMobilePhone(this.state.login, ['ru-RU']) ||
        isMobilePhone(this.state.login, ['kk-KZ'])
      )
    ) {
      this.setState({ [`${prop}Error`]: true })
    } else if (this.state[prop].length < 1) {
      this.setState({ [`${prop}Error`]: true })
    }
  }

  render() {
    const { open, handleClose, apps, appsError, classes, usersError } =
      this.props

    const OPTIONS =
      apps &&
      apps.map((s) => ({
        value: s.app_id,
        label: s.name,
      }))
    const SELECTED_OPTIONS =
      OPTIONS && OPTIONS.filter((o) => this.state.app_ids.includes(o.value))

    return (
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        TransitionComponent={Transition}
      >
        <AppBar className={classes.header}>
          <Toolbar className={classes.center}>
            <Typography variant="title" color="inherit">
              Пригласить нового пользователя
            </Typography>
          </Toolbar>
        </AppBar>

        {!apps ? (
          <Loader msg="Загружаем список приложений..." error={appsError} />
        ) : (
          <Form>
            <TextField
              autoFocus
              id="login"
              label="E-mail / телефон"
              type="text"
              placeholder="example@example.com"
              fullWidth
              margin="dense"
              onChange={this.handleChange('login')}
              onBlur={this.handleBlur('login')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
              error={this.state.loginError}
              autoComplete="email"
            />

            <Margin>
              <Select
                options={OPTIONS}
                selected={SELECTED_OPTIONS}
                handleSelect={this.handleSelect}
                placeholder="Список доступных приложений"
              />
            </Margin>
            <ActionsWrapper>
              <Button secondary rounded onClick={handleClose} tabIndex="-1">
                <CloseIcon style={{ marginRight: '8px' }} />
                Отмена
              </Button>

              <Button
                onClick={this.handleinviteUser}
                primary
                rounded
                // size="small"
              >
                <Send style={{ marginRight: '8px' }} />
                Пригласить
              </Button>
            </ActionsWrapper>
          </Form>
        )}
        <Toast
          open={this.state.snackbarErr}
          handleClose={this.toggleModal('snackbarErr')}
          variant="error"
        >
          {usersError || 'Заполните все поля'}
        </Toast>
      </Dialog>
    )
  }
}

const mapStateToProps = ({ apps, auth: { user: authorizedUser }, users }) => ({
  apps: apps.collection,
  appsError: apps.error,
  authorizedUser,
  usersError: users.error,
})

export default connect(mapStateToProps)(
  withStyles(styles)(withRouter(InviteUserModal))
)
