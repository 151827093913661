//@flow
import * as types from './types'

type State = {
  clients: ?(Object[]),
  error: ?string,
  userApps: ?(Object[]),
  collection: ?(Object[]),
  error: ?string,
}

type Action = {
  type: string,
  payload?: Object | string | Object[],
  err: string,
}

const initialState: State = {
  clients: null,
  userApps: null,
  collection: null,
  error: null,
}

const clients = (
  state: State = initialState,
  { type, payload, err }: Action
) => {
  switch (type) {
    case types.GET_USER_APPS_SUCCESS:
      return { ...state, userApps: payload, error: null }
    case types.GET_USER_APPS_ERROR:
      return { ...state, error: err }
    case types.GET_APPS_SUCCESS:
      return { ...state, collection: payload, error: null }
    case types.GET_APPS_ERROR:
      return { ...state, error: err }
    case types.GET_APP_ABILITIES_ERROR:
      return { ...state, error: err }
    default:
      return state
  }
}

export default clients
