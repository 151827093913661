const styles = theme => ({
  column: {
    display: 'flex',
    alignItems: 'left',
    marginBottom: '1.625rem',
    flexWrap: 'wrap',
  },
  row: {
    display: 'flex',
    width: '100%',
    marginBottom: '.625rem',
  },
  // actionsPanel: {
  //   paddingTop: '10px',
  //   paddingBottom: '10px',
  //   paddingRight: '20px',
  //   overflow: 'visible',
  //   justifyContent: 'flex-end',
  // },
  avatar: {
    backgroundColor: theme.palette.secondary['500'],
    color: 'black',
  },
  card: {
    overflow: 'visible',
    marginBottom: '2rem',
  },
  subcolumn: {
    flexBasis: '15%',
    alignSelf: 'flex-end',
  },
  permissionColumn: {
    flexBasis: '85%',
  },
  action: {
    margin: 0,
  },
  flex: {
    display: 'flex',
    alignSelf: 'flex-end',
  },
  error: {
    color: theme.palette.error['main'],
  },
})

export default styles
