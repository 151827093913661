import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography,
  TextField,
  Zoom,
} from '@material-ui/core'
import { Button, useInput } from 'sputnik-ui'
import { AsYouType } from 'libphonenumber-js'
import { useDispatch } from 'react-redux'

import { confirmPhoneNumber } from 'store/users/operations'

const Transition = props => <Zoom in {...props} />

export default function ConfirmPhoneModal({
  open,
  handleClose,
  phone,
  onSuccess,
  ...props
}) {
  const dispatch = useDispatch()

  const { value: smsCode, bind: bindSmsCode } = useInput('')

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(confirmPhoneNumber(phone, smsCode)).then(onSuccess)
  }
  return (
    <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}>
      <DialogTitle>Подвердите свой номер телефона</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Typography color="textSecondary">
            На номер {new AsYouType('RU').input(phone)} была отправлена SMS с
            кодом подтверждения.
          </Typography>
          <TextField
            label="Код из смс"
            fullWidth
            style={{ margin: '1rem 0' }}
            autoFocus
            {...bindSmsCode}
          ></TextField>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" rounded type="submit">
            Подтвердить
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
