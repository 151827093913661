import React from 'react'
import { connect } from 'react-redux'
import { Select, Loader, Button } from 'sputnik-ui'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'

import { getApps, updateUser } from 'store/operations'

// codereview-disable-next-line
const Margin = styled.div`
  margin-top: 1.625rem;
  width: 100%;
`

const Transition = props => <Slide direction="up" {...props} />

class AddAppModal extends React.Component {
  state = {
    app_ids: this.props.userApps.map(s => s.app_id),
  }

  componentDidMount() {
    this.props.dispatch(getApps())
  }

  handleSelect = options =>
    this.setState({ app_ids: options.map(o => o.value) })

  handleChange = prop => e => this.setState({ [prop]: e.target.value })

  updateUser = () => {
    this.props
      .dispatch(
        updateUser({
          userId: this.props.match.params.id,
          app_ids: this.state.app_ids,
        }),
      )
      .then(this.props.handleClose)
  }

  render() {
    const { open, handleClose, apps } = this.props

    const OPTIONS =
      apps &&
      apps.map(s => ({
        value: s.app_id,
        label: s.name,
      }))

    const SELECTED_OPTIONS =
      OPTIONS && OPTIONS.filter(o => this.state.app_ids.includes(o.value))

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        TransitionComponent={Transition}
        PaperProps={{ style: { overflow: 'visible' } }}
      >
        <DialogTitle id="alert-dialog-title">
          Редактирование списка приложений
        </DialogTitle>
        <DialogContent style={{ overflow: 'visible' }}>
          {!apps ? (
            <Loader msg="Загружаем список приложений..." />
          ) : (
            <form>
              <Margin>
                <Select
                  options={OPTIONS}
                  selected={SELECTED_OPTIONS}
                  handleSelect={this.handleSelect}
                  placeholder="Список доступных приложений"
                />
              </Margin>
            </form>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} secondary rounded autoFocus>
            Отмена
          </Button>
          <Button
            onClick={this.updateUser}
            rounded
            color="primary"
            variant="raised"
          >
            Обновить
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = ({ apps }) => ({
  apps: apps.collection,
  userApps: apps.userApps,
})

export default connect(mapStateToProps)(withRouter(AddAppModal))
