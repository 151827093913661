// import React...
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Home, User, MyProfilePage, NotFound, ResetPassword } from 'components'
import requireAuth from 'utils/requireAuth'

const PrivateRoutes = requireAuth(() => (
  <Switch>
    <Route component={Home} exact path="/" />
    <Route component={MyProfilePage} exact path="/user/me" />
    <Route component={User} exact path="/user/:id" />
    <Route component={NotFound} path="/" />
  </Switch>
))

const Routes = () => (
  <Switch>
    <Route path="/password_resets/:id" component={ResetPassword} />
    <PrivateRoutes />
  </Switch>
)

export default Routes
