import React, { useState, useEffect } from 'react'
import { Typography } from '@material-ui/core'
import { Loader } from 'sputnik-ui'
import { withRouter } from 'react-router-dom'
import Api from 'utils/api'

const styles = {
  fullPage: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
}

const ResetPasswordPage = ({ match }) => {
  const [didSendNewPassword, setDidSendNewPassword] = useState(false)

  const {
    params: { id },
  } = match

  useEffect(() => {
    Api.request(`/password_resets/${id}`, {
      method: 'PUT',
    }).then(() => setDidSendNewPassword(true))
  }, [match])

  return didSendNewPassword ? (
    <div style={styles.fullPage}>
      <span
        role="img"
        aria-label="relieved face"
        style={{ fontSize: '1.5rem' }}
      >
        😌
      </span>
      <Typography variant="h5">
        Новый пароль был выслан на ваш email.
      </Typography>
    </div>
  ) : (
    <Loader fullPage />
  )
}

export default withRouter(ResetPasswordPage)
