//@flow
export const GET_APPS_REQUEST: string = 'clients/GET_APPS_REQUEST'
export const GET_APPS_SUCCESS: string = 'clients/GET_APPS_SUCCESS'
export const GET_APPS_ERROR: string = 'clients/GET_APPS_ERROR'

export const GET_USER_APPS_REQUEST: string = 'clients/GET_USER_APPS_REQUEST'
export const GET_USER_APPS_SUCCESS: string = 'clients/GET_USER_APPS_SUCCESS'
export const GET_USER_APPS_ERROR: string = 'clients/GET_USER_APPS_ERROR'

export const GET_APP_ABILITIES_REQUEST: string =
  'clients/GET_APP_ABILITIES_REQUEST'
export const GET_APP_ABILITIES_SUCCESS: string =
  'clients/GET_APP_ABILITIES_SUCCESS'
export const GET_APP_ABILITIES_ERROR: string = 'clients/GET_APP_ABILITIES_ERROR'

export const GET_ABILITY_ACTIONS_REQUEST: string =
  'clients/GET_ABILITY_ACTIONS_REQUEST'
export const GET_ABILITY_ACTIONS_SUCCESS: string =
  'clients/GET_ABILITY_ACTIONS_SUCCESS'
export const GET_ABILITY_ACTIONS_ERROR: string =
  'clients/GET_ABILITY_ACTIONS_ERROR'

export type requestAction = {
  type: string,
}

export type successAction = {
  type: string,
  payload: Object | Array<Object> | string,
}

export type errorAction = {
  type: string,
  err: string,
}

export type App = {
  id: number,
  name: string,
  app_id: string,
  app_secret: string,
}
