import * as types from './types'

export const enableFeatureRequest = () => ({
  type: types.ENABLE_FEATURE_REQUEST,
})
export const enableFeatureSuccess = (msg, feature) => ({
  type: types.ENABLE_FEATURE_SUCCESS,
  payload: { msg, feature },
})
export const enableFeatureFailure = error => ({
  type: types.ENABLE_FEATURE_FAILURE,
  payload: { error },
})

export const disableFeatureRequest = () => ({
  type: types.DISABLE_FEATURE_REQUEST,
})
export const disableFeatureSuccess = (msg, feature) => ({
  type: types.DISABLE_FEATURE_SUCCESS,
  payload: { msg, feature },
})
export const disableFeatureFailure = error => ({
  type: types.DISABLE_FEATURE_FAILURE,
  payload: { error },
})
