//@flow
import * as React from 'react'
//$FlowFixMe
import { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { theme, i18n, Loader, ThemeProvider, Metrika } from 'sputnik-ui'
import { Provider } from 'react-redux'
import registerServiceWorker from './registerServiceWorker'
import store from 'store/store'
//$FlowFixMe
import { BrowserRouter } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import AOS from 'aos'
import App from './App'

const Root = (): React.Node => {
  useEffect(() => {
    AOS.init({
      // easing: 'ease-out-quad',
      duration: 400,
      once: true,
    })
  }, [])
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <I18nextProvider i18n={i18n}>
            <React.Suspense fallback={<Loader />}>
              <App />
              <Metrika />
            </React.Suspense>
          </I18nextProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  )
}

const node = document.getElementById('root')

if (node === null) {
  throw new Error('no node element')
}

ReactDOM.render(<Root />, node)
registerServiceWorker()
