import React from 'react'
import TableHead from '@material-ui/core/TableHead'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import PropTypes from 'prop-types'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import columnData from './data'
import AccountIcon from '@material-ui/icons/AccountCircle'
import BusinessIcon from '@material-ui/icons/Business'
import EmailIcon from '@material-ui/icons/Email'
import { withStyles } from '@material-ui/core/styles'
import { PhoneIphone } from '@material-ui/icons'

const styles = theme => ({
  cell: {
    backgroundColor: 'white',
  },
  label: {
    color: 'black',
  },
})

class MyTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const { order, orderBy, classes } = this.props

    return (
      <TableHead>
        <TableRow>
          {columnData.map(column => {
            const active = orderBy === column.id
            return (
              <TableCell
                key={column.id}
                numeric={column.numeric}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.id ? order : false}
                className={classes.cell}
              >
                <Tooltip
                  title="Сортировать"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={active}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                    className={classes.label}
                  >
                    {column.id === 'email' && (
                      <EmailIcon style={{ marginRight: '.625rem' }} />
                    )}
                    {column.id === 'full_name' && (
                      <AccountIcon style={{ marginRight: '.625rem' }} />
                    )}
                    {column.id === 'company' && (
                      <BusinessIcon style={{ marginRight: '.625rem' }} />
                    )}
                    {column.id === 'phone' && (
                      <PhoneIphone style={{ marginRight: '.625rem' }} />
                    )}

                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            )
          })}
        </TableRow>
      </TableHead>
    )
  }
}

MyTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

export default withStyles(styles)(MyTableHead)
