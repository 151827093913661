//@flow
export const GET_COMPANIES_REQUEST: string = 'users/GET_COMPANIES_REQUEST'
export const GET_COMPANIES_SUCCESS: string = 'users/GET_COMPANIES_SUCCESS'
export const GET_COMPANIES_ERROR: string = 'users/GET_COMPANIES_ERROR'

export type requestAction = {
  type: string,
}

export type successAction = {
  type: string,
  payload?: any,
}

export type errorAction = {
  type: string,
  payload: string,
}
