//@flow
import * as React from 'react'
import TableHead from './TableHead'
import { withRouter } from 'react-router-dom'
import { Container } from 'sputnik-ui'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'

type User = {
  id: number,
  email: string,
  company: string,
  full_name: string,
  phone: string,
}

type P = {
  history: Object,
  users: Object[],
  handleChangePage: Function,
  page: number,
  limit: number,
  handleChangeRowsPerPage: Function,
  count: number,
}

type S = {
  order: string,
  orderBy: string,
  data: Object[],
}

class UsersTable extends React.Component<P, S> {
  state = {
    order: 'asc',
    orderBy: 'email',
    data: [],
  }

  static getDerivedStateFromProps(props: P, state: S) {
    if (props.users) {
      // && props.users.length !== state.data.length
      return {
        //$FlowFixMe
        data: props.users.sort((a, b) => (a.email < b.email ? 1 : -1)),
      }
    }
    return state
  }

  handleRequestSort = (event: SyntheticEvent<Event>, property: string) => {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({ order, orderBy })
  }

  getSorting = (order: string, orderBy: string) => {
    return order === 'desc'
      ? (a: Object, b: Object) => (b[orderBy] < a[orderBy] ? -1 : 1)
      : (a: Object, b: Object) => (a[orderBy] < b[orderBy] ? -1 : 1)
  }

  render() {
    const { data, order, orderBy } = this.state
    const { history, page, limit } = this.props

    return (
      <React.Fragment>
        <div style={{ width: '100%' }}>
          <Table
            style={{
              width: '100%',
              tableLayout: 'fixed',
            }}
          >
            <TableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={limit}
            />

            <TableBody>
              {data.sort(this.getSorting(order, orderBy)).map((n: User) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={n.id}
                    onClick={() => history.push(`/user/${n.id}`)}
                    style={{
                      cursor: 'pointer',
                    }}
                    data-aos="fade-left"
                  >
                    <TableCell component="th" scope="row" padding="dense">
                      {n.email}
                    </TableCell>
                    <TableCell component="th" scope="row" padding="dense">
                      {n.full_name}
                    </TableCell>
                    <TableCell>{n.company}</TableCell>
                    <TableCell>{n.phone}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            background: 'white',
            boxShadow: '0 0 20px rgba(0,0,0,0.1)',
          }}
          component="div"
          count={this.props.count}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 20, 30]}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          labelRowsPerPage="Строк на странице:"
          labelDisplayedRows={({ from, to, count }) =>
            //$FlowFixMe
            `${from}-${to} из ${count}`
          }
          onChangePage={this.props.handleChangePage}
          onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
        />
      </React.Fragment>
    )
  }
}

export default withRouter(UsersTable)
