export default [
  {
    id: 'email',

    disablePadding: false,
    label: 'E-mail',
  },
  {
    id: 'full_name',

    disablePadding: false,
    label: 'Имя',
  },
  {
    id: 'company',

    disablePadding: false,
    label: 'Компания',
  },
  {
    id: 'phone',

    disablePadding: false,
    label: 'Телефон',
  },
]
