//@flow
import * as types from './types'

export const getCompaniesRequest = (): types.requestAction => ({
  type: types.GET_COMPANIES_REQUEST,
})

export const getCompaniesSuccess = (
  companies: Object[]
): types.successAction => ({
  type: types.GET_COMPANIES_SUCCESS,
  payload: companies,
})

export const getCompaniesError = (err: string): types.errorAction => ({
  type: types.GET_COMPANIES_ERROR,
  payload: err,
})
