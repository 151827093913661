//@flow
import * as types from './types'

const initialState = {
  collection: null,
}

const companies = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_COMPANIES_SUCCESS:
      return { ...state, collection: payload, error: null }
    case types.GET_COMPANIES_ERROR:
      return { ...state, error: null }
    default:
      return state
  }
}

export default companies
