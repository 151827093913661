//@flow
import * as actions from './actions'
import Api from 'utils/api'

export const getCompanies =
  ({ q, page, limit }) =>
  async (dispatch) => {
    dispatch(actions.getCompaniesRequest())
    try {
      const companies = await Api.request(
        `/companies?q=${q}&page=${page + 1}&limit=${limit}`
      )
      dispatch(actions.getCompaniesSuccess(companies))
    } catch (err) {
      dispatch(actions.getCompaniesError(err))
    }
  }
