//@flow
import * as types from './types'

export const getAppsRequest = (): types.requestAction => ({
  type: types.GET_APPS_REQUEST,
})

export const getAppsSuccess = (
  clients: Array<types.App>
): types.successAction => ({
  type: types.GET_APPS_SUCCESS,
  payload: clients,
})

export const getAppsError = (err: string): types.errorAction => ({
  type: types.GET_APPS_ERROR,
  err,
})

export const getUserAppsRequest = (): types.requestAction => ({
  type: types.GET_USER_APPS_REQUEST,
})

export const getUserAppsSuccess = (
  clients: types.App[]
): types.successAction => ({
  type: types.GET_USER_APPS_SUCCESS,
  payload: clients,
})

export const getUserAppsError = (err: string): types.errorAction => ({
  type: types.GET_USER_APPS_ERROR,
  err,
})

export const getAppAbilitiesRequest = (): types.requestAction => ({
  type: types.GET_APP_ABILITIES_REQUEST,
})

export const getAppAbilitiesSuccess = (
  abilities: any[]
): types.successAction => ({
  type: types.GET_APP_ABILITIES_SUCCESS,
  payload: abilities,
})

export const getAppAbilitiesError = (err: string): types.errorAction => ({
  type: types.GET_APP_ABILITIES_ERROR,
  err,
})

export const getAbilityActionsRequest = (): types.requestAction => ({
  type: types.GET_ABILITY_ACTIONS_REQUEST,
})

export const getAbilityActionsSuccess = (
  actions: Array<any>
): types.successAction => ({
  type: types.GET_ABILITY_ACTIONS_SUCCESS,
  payload: actions,
})

export const getAbilityActionsError = (err: string): types.errorAction => ({
  type: types.GET_ABILITY_ACTIONS_ERROR,
  err,
})
