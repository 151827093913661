import React from 'react'

import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'

import BlockIcon from '@material-ui/icons/Block'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import AddIcon from '@material-ui/icons/AddToQueue'
import WorkIcon from '@material-ui/icons/Work'

const UserCardActions = ({
  currentUser,
  classes,
  toggleModal,
  toggleUserBlock,
  authorizedUser,
}) => {
  return (
    <div className={classes.flex}>
      {authorizedUser.company.uuid ===
        'b1782e4f-9198-49d1-b5aa-7bdba9c87d21' && (
        <Tooltip title="Редактировать компанию" placement="top">
          <IconButton color="primary" onClick={toggleModal('editCompanyModal')}>
            <WorkIcon />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Редактировать список приложений" placement="top">
        <IconButton color="primary" onClick={toggleModal('addAppModal')}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={
          currentUser.is_blocked
            ? 'Разблокировать пользователя'
            : 'Заблокировать пользователя'
        }
        placement="top"
      >
        <IconButton
          className={!currentUser.is_blocked && classes.error}
          onClick={toggleUserBlock}
        >
          <BlockIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Удалить пользователя" placement="top">
        <IconButton
          className={classes.error}
          onClick={toggleModal('deleteUserModal')}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </div>
  )
}

export default UserCardActions
