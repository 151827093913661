export const ENABLE_FEATURE_REQUEST: string = 'features/ENABLE_FEATURE_REQUEST'
export const ENABLE_FEATURE_SUCCESS: string = 'features/ENABLE_FEATURE_SUCCESS'
export const ENABLE_FEATURE_FAILURE: string = 'features/ENABLE_FEATURE_FAILURE'

export const DISABLE_FEATURE_REQUEST: string =
  'features/DISABLE_FEATURE_REQUEST'
export const DISABLE_FEATURE_SUCCESS: string =
  'features/DISABLE_FEATURE_SUCCESS'
export const DISABLE_FEATURE_FAILURE: string =
  'features/DISABLE_FEATURE_FAILURE'
