//@flow
import * as types from './types'

type State = {
  users: ?Array<Object>,
  currentUser: ?Object,
  error: ?string,
  msg: ?string,
}

type Action = {
  +type: string,
  +payload: any,
}

const initialState: State = {
  currentUser: null,
  users: null,
  error: null,
  msg: null,
}

const users = (state: State = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.GET_CURRENT_USER_SUCCESS:
      return { ...state, currentUser: payload, error: null }
    case types.GET_CURRENT_USER_ERROR:
      return { ...state, error: payload }
    case types.GET_USERS_SUCCESS:
      return { ...state, users: payload, error: null }
    case types.GET_USERS_ERROR:
      return { ...state, error: payload }
    case types.DELETE_USER_SUCCESS:
      return { ...state, error: null }
    case types.DELETE_USER_ERROR:
      return { ...state, error: payload }
    case types.INVITE_USER_ERROR:
      return { ...state, error: payload }
    case types.UPDATE_CURRENT_USER_SUCCESS:
      return { ...state, msg: payload.msg, error: null }
    case types.UPDATE_CURRENT_USER_ERROR:
      return { ...state, error: payload }
    case types.CONFIRM_PHONE_NUMBER_SUCCESS:
      return { ...state, msg: payload.msg }
    case types.CONFIRM_PHONE_NUMBER_ERROR:
      return { ...state, error: payload.error }
    case types.UPDATE_USER_ERROR:
      return { ...state, error: payload }
    case types.CLEAR_USERS:
      return { ...state, users: null }
    default:
      return state
  }
}

export default users
